<template>
  <div id="thailandPaytoday">
    <div class="content_box">
      <div class="inner_max">
        <div class="page-header">{{ $t('menu.depositFund') }}</div>
        <div class="form_deposit" v-show="firstShow">
          <h4>{{ $t('deposit.thaiPaytoday.header') }}</h4>
          <div class="content">
            <div class="info_box">
              <img src="@/assets/images/channel/local_transfer.png" alt="" />
              <p>{{ $t('deposit.thaiPaytoday.desc') }}</p>
              <ul>
                <li>{{ $t('deposit.thaiPaytoday.inst1') }}</li>
                <li>{{ $t('deposit.thaiPaytoday.inst2') }}</li>
              </ul>
            </div>
            <div class="form_main">
              <p class="title">{{ $t('deposit.thaiPaytoday.form.header') }}</p>
              <el-form label-position="top" :model="paytodayForm" ref="paytodayForm" status-icon :rules="paytodayRules">
                <div class="form_box">
                  <ul class="clearfix">
                    <li class="fl">
                      <AccountNumber
                        supportedCurrencies="USD"
                        @setCurrency="setCurrency"
                        @setAccountNumber="setAccountNumber"
                      ></AccountNumber>
                    </li>
                    <li class="fr">
                      <el-form-item :label="$t('common.field.amt')" prop="amount">
                        <numeric-input
                          v-model="paytodayForm.amount"
                          :currency="accountCurrency"
                          :precision="2"
                        ></numeric-input>
                      </el-form-item>
                    </li>
                  </ul>
                  <ul class="clearfix">
                    <li class="fl">
                      <el-form-item :label="$t('common.field.imptNotes')">
                        <el-input v-model="paytodayForm.notes" data-testid="applicationNotes"></el-input>
                      </el-form-item>
                    </li>
                    <li class="fr">
                      <p class="mb-2">
                        <span class="required_icon">*</span>
                        {{ $t('deposit.default.rate.rate', { oldCurrency: 'USD', newCurrency: 'THB' }) }}
                        <span>{{ rate }}</span>
                      </p>
                      <p>
                        <span class="required_icon">*</span> THB: <span>{{ rateChange }}</span>
                      </p>
                    </li>
                  </ul>
                </div>
                <el-button type="primary" :loading="loading" @click="submitForm()" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form>
            </div>
          </div>
        </div>
        <div ref="thailand_second" class="thailand_second" v-show="sencondShow">
          <div class="form_box">
            <ul class="mb-5">
              <li class="mb-1 text-white">
                {{ $t('common.field.accNum') }}:
                <span>{{ paytodayForm.accountNumber }}</span>
              </li>
              <li class="mb-1 text-white">
                {{ $t('common.field.amtUSD') }}:
                <span>${{ paytodayForm.amount }}</span>
              </li>
              <li class="mb-1 text-white">
                {{ $t('deposit.default.rate.rate', { oldCurrency: 'USD', newCurrency: 'THB' }) }}
                <span>{{ rate }}</span>
              </li>
              <li class="mb-1 text-white">
                THB: <span>{{ rateChange }}</span>
              </li>
            </ul>
            <div class="code_info text-center mb-3">
              <strong class="text-white mb-1 d-block">{{ $t('deposit.thaiPaytoday.scan') }}</strong>
              <img :src="mobileImg" class="d-block mb-5 me-auto ms-auto" alt="" />
              <p class="text-white">{{ $t('deposit.thaiPaytoday.exp', { expireTime: expireTime }) }}</p>
              <p class="text-white" v-html="$t('deposit.thaiPaytoday.reminder')"></p>
            </div>
          </div>
          <router-link :to="{ name: 'home' }" class="el-button d-block me-auto ms-auto w-100">
            {{ $t('common.button.bkToHm') }}
          </router-link>
        </div>
        <DepositResultDialog v-bind:display.sync="successFlag" />
      </div>
    </div>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import rounding from '@/util/rounding';
import DepositResultDialog from '@/components/deposit/DepositResultDialog';
import AccountNumber from '@/components/form/AccountNumber';
import mixin from '@/mixins/page/deposit';
import { apiQuery_paymentChannel, apiQueryRate, apiPaytoday_payment } from '@/resource';

export default {
  name: 'ThailandPaytoday',
  components: { NumericInput, DepositResultDialog, AccountNumber },
  mixins: [mixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (!Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.minLimit) {
        callback(new Error(this.$t('common.formValidation.amtLarger', { minLimit: this.minLimit })));
      } else if (parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: this.accountCurrency })
          )
        );
      } else {
        callback();
      }
    };
    return {
      paytodayForm: {
        accountNumber: '',
        amount: '',
        notes: ''
      },
      paytodayRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ]
      },
      successFlag: false,
      firstShow: true,
      sencondShow: false,
      paymentChannel: '',
      rate: 0.0,
      htmlPage: '',
      mobileImg: '',
      expireTime: '',
      updateExpireTime: null
    };
  },
  computed: {
    rateChange() {
      return rounding(Math.ceil, this.rate * this.paytodayForm.amount, 2);
    }
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.paytodayForm.accountNumber = accountNumber;
    },
    getExpireMinutesAndSeconds(expireTime) {
      if (!expireTime || expireTime <= 0) {
        return '00:00';
      }
      var date = new Date(expireTime);
      var minutes = date.getMinutes();
      var seconds = date.getSeconds();
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      return minutes + ':' + seconds;
    },
    success(msg) {
      this.htmlPage = msg;
      if (this.htmlPage.length > 0) {
        console.log(this.htmlPage);
        var resp = JSON.parse(this.htmlPage);
        if (resp.code === '0001') {
          this.mobileImg = resp.image;
          var expire = resp.expire;
          this.updateExpireTime = setInterval(() => {
            this.expireTime = this.getExpireMinutesAndSeconds(expire);
            if (this.expireTime === '00:00') {
              expire = 0;
              clearInterval(this.updateExpireTime);
              this.expireTime = 'Exipred';
              this.firstShow = false;
              this.sencondShow = false;
              this.successFlag = true;
            } else {
              expire -= 1000;
            }
          }, 1000);
          this.firstShow = false;
          this.sencondShow = true;
        } else {
          this.loading = false;
          this.errorMessage(this.$t('deposit.default.failed'));
        }
      } else {
        this.loading = false;
        this.errorMessage(this.$t('deposit.default.failed'));
      }
    },
    submitForm() {
      this.$refs['paytodayForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              this.depositSubmitWithRate(result, this.success);
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    submitDeposit() {
      return apiPaytoday_payment(
        {
          mt4Account: this.paytodayForm.accountNumber,
          operateAmount: this.paytodayForm.amount,
          applicationNotes: this.paytodayForm.notes,
          paymentChannel: this.paymentChannel,
          depositAmount: this.rateChange,
          rate: this.rate
        },
        this.token
      );
    },
    queryPaymentChannel() {
      apiQuery_paymentChannel()
        .then(resp => {
          if (resp.data.code == 0) this.paymentChannel = resp.data.data;
        })
        .catch(err => {
          this.errorMessage(this.$t('deposit.default.rate.result.channelError'));
        });
    },
    queryRate() {
      apiQueryRate()
        .then(resp => {
          if (resp.data.code == 0) this.rate = resp.data.data;
        })
        .catch(err => {
          this.errorMessage(this.$t('deposit.thaiPaytoday.result.rateError'));
        });
    }
  },
  mounted() {
    this.queryPaymentChannel();
    this.queryRate();
  },
  destroyed() {
    clearInterval(this.updateExpireTime);
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';

.thailand_second strong {
  font-weight: bold;
}

.thailand_second .el-button {
  max-width: 298px;
}
</style>
